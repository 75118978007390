.desktop-layout {
  width: 100%;
  background-color: var(--sub-bg-color);
  display: grid;
  grid-template-columns: 15% calc(100% - 15%);
  grid-template-rows: 100vh;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.desktop-body {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px calc(100vh - 60px);
  box-sizing: border-box;
}

.desktop-sidebar-logo {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.desktop-header {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.desktop-sidebar-logo img {
  width: 200px;
}

.desktop-content-container {
  width: 100%;
  display: grid;
  grid-template-columns: calc(100% - 15%) 15%;
}

.desktop-content {
  background-color: var(--bg-color);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  overflow: auto;
  height: calc(100vh - 60px);
}
