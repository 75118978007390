.faq-banner,
.faq-item {
  background-color: var(--sub-bg-color);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.faq-banner {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  justify-items: center;
}
