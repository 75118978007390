.notifications-container {
  display: grid;
  justify-content: start;
  justify-items: start;
  box-sizing: border-box;
  grid-template-rows: 40px auto;
  max-height: calc(100vh - 60px);
}

.notification-art-container {
  display: grid;
  grid-template-rows: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.notification-art-container img {
  width: 100%;
}

.notification-art-container p {
  text-align: center;
}

.notification-item-container {
  width: 90%;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 1rem;
}

.notification-item-container li {
  width: 100% !important;
  padding: 0;
  margin: 0;
}