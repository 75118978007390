.item-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  box-sizing: border-box;
  justify-content: start;
  align-items: start;
  align-content: start;
  justify-items: start;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.item-container div {
  width: 100%;
  background-color: var(--sub-bg-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.item-container div:hover {
  background: var(--alter-bg-color);
}

.item-container li p {
  text-align: center;
  color: var(--text-color);
  font-weight: 500;
  font-size: 1.2em;
}

.item-container a {
  text-decoration: none !important;
}

@media screen and (max-width: 1800px) {
  .item-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .item-container li {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1600px) {
  .item-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .item-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .item-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-items: center;
  }
}
