.worker-table-status-NONE {
  background-color: var(--sub-bg-color);
}

.worker-table-status-INSUFFICIENT_PERMISSIONS,
.worker-table-status-LOW_HEART_RATE {
  background-color: var(--alert-bg-yellow);
}

.worker-table-status-SOS,
.worker-table-status-MAN_DOWN {
  background-color: var(--alert-bg-yellow);
}
