/* src/App.css */
.flex-container {
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 100vh;
  box-sizing: border-box;
}

.left-box {
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
}

.right-box {
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: center;
  grid-template-columns: 250px;
  box-sizing: border-box;
  background-color: rgb(136, 30, 0);
  background-image: url(../../assets/maze.jpg);
  background-repeat: repeat;
  background-blend-mode: saturation;
  animation: moveBackground 25s infinite linear; /* Adjust the duration as needed */
}

#loader-container {
  width: 100%;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 55, 0, 0.5);
  display: grid;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  opacity: 1;
}

#loader-container p {
  text-align: center;
  color: white;
  font-size: 1.5em;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

#loader-container svg * {
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

#loader-container .circle {
  animation: rotation 8s infinite linear;
}
#loader-container .circle_s {
  animation: rotation 4s infinite linear;
}

@keyframes moveBackground {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -100% 100%;
  }
}

@media (max-width: 1400px) {
  .right-box {
    display: none;
  }

  .flex-container {
    grid-template-columns: 100%;
  }
}
