#notice-container {
  width: 100%;
  z-index: 9000;
  bottom: 0;
  position: fixed;
  display: grid;
  grid-template-columns: 100%;
  justify-content: start;
  align-content: start;
  justify-items: start;
  align-items: start;
  opacity: 1;
}
